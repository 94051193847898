<template>
  <div>
    <div class="container">
      <div class="inquiry-title">문의내역</div>
      <div class="tabs-container">
        <div class="tabs" ref="tabs">
          <div
            class="tab unselect"
            v-for="(tab, index) in tabs"
            :key="index"
            :class="{ active: tab.value === selectedTab }"
            @click="clickTab(tab.value)"
          >
            {{ tab.label }}
          </div>
        </div>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: 'Inquiry',
  components: {},
  mixins: [],
  props: {},
  data() {
    return {
      tabs: [
        {
          label: '템플릿 제작 문의',
          value: 'my_template_manufacture_inquiry',
        },
        {
          label: '템플릿 문의',
          value: 'my_template_inquiry',
        },
        {
          label: '맞춤제작 문의',
          value: 'inquiry',
        },
        {
          label: 'API 견적 문의',
          value: 'api',
        },
        {
          label: '소스파일 등록 문의',
          value: 'my_source_file_inquiry',
        },
      ],
      selectedTab: '',
    };
  },
  computed: {},
  watch: {
    $route(n) {
      if (n.path !== `/mypage/inquiry/${this.selectedTab}`) {
        this.selectedTab = n.path.split('/').slice(-1)[0];
      }

      this.scrollToActiveTab();
    },
  },
  created() {
    if (this.$route.path === '/mypage/inquiry') {
      this.clickTab(this.tabs[0].value);
    } else {
      this.tabs.forEach((tab) => {
        if (this.$route.path === `/mypage/inquiry/${tab.value}`) {
          this.clickTab(tab.value);
        }
      });
    }
  },
  mounted() {
    setTimeout(() => {
      this.scrollToActiveTab();
    }, 1000);
  },
  methods: {
    clickTab(value) {
      this.selectedTab = value;
      this.$router.push(`/mypage/inquiry/${value}`);
    },
    scrollToActiveTab() {
      this.$nextTick(() => {
        let activeTab = this.tabs.findIndex((tab) => tab.value === this.$route.path.split('/').slice(-1)[0]);
        if (activeTab !== -1) {
          const tabElement = this.$refs.tabs.children[activeTab];
          if (tabElement) {
            tabElement.scrollIntoView({
              behavior: 'smooth',
              block: 'nearest',
              inline: 'center',
            });
          }
        }
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
@import '~assets/css/lp_main'

.container
  padding 40px 0
  .inquiry-title
    font-size 24px
    font-weight 700
    line-height 36px
    color $main
    margin-bottom 24px

  .tabs
    display flex
    align-items center
    gap 24px
    border-bottom 1px solid $gray
    .tab
      font-size 16px
      font-weight 700
      color $sub3
      cursor pointer
      padding 8px 0 6px 0
      border-bottom 2px solid transparent
      &.active
        color $main
        font-weight 700
        border-bottom 2px solid $main

@media screen and (max-width: 1024px)
  .container
    padding 24px 0
    .inquiry-title
      padding 0 16px
      font-size 18px
      line-height 24px
      margin-bottom 16px
    .tabs-container
      overflow-x auto
      display block
      overflow-x auto
      white-space nowrap
      width 100%
      justify-content flex-start !important
      .tabs
        display inline-flex
        justify-content flex-start
        align-items center
        gap 24px
        .tab
          display inline-flex
          justify-content center
          align-items center
          border-bottom 2px solid transparent
          &.active
            color $main
            font-weight 700
            border-bottom 2px solid $main
        .tab:first-child
          margin-left 16px
        .tab:last-child
          margin-right 16px
</style>
